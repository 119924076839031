<template>
  <div class="container bg-white" id="pembatalanSewa">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t(tosString + '.pembatalanSewa.title') }}</h3>
        <div class="divider"></div>
        <ol>
          <li>{{ $t(tosString + '.pembatalanSewa.line1') }}</li>
          <li>{{ $t(tosString + '.pembatalanSewa.line2') }}</li>
          <li>
            {{ $t(tosString + '.pembatalanSewa.line3') }}
            <ul>
              <li>{{ $t(tosString + '.pembatalanSewa.line4') }}</li>
              <li>{{ $t(tosString + '.pembatalanSewa.line5') }}</li>
              <li>{{ $t(tosString + '.pembatalanSewa.line6') }}</li>
              <li>{{ $t(tosString + '.pembatalanSewa.line7') }}</li>
              <li>{{ $t(tosString + '.pembatalanSewa.line8') }}</li>
              <li>{{ $t(tosString + '.pembatalanSewa.line9') }}</li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'penarikanDana',
  props: ['tosString'],
};
</script>
