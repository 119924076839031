<template>
  <div class="container bg-white" id="akun">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t(tosString + '.akun.title') }}</h3>
        <div class="divider"></div>
        <ol>
          <li>{{ $t(tosString + '.akun.line1') }}</li>
          <li>{{ $t(tosString + '.akun.line2') }}</li>
          <li v-html="$t(tosString + '.akun.line3')"></li>
          <li>{{ $t(tosString + '.akun.line4') }}</li>
          <li>{{ $t(tosString + '.akun.line5') }}</li>
          <li>{{ $t(tosString + '.akun.line6') }}</li>
          <li>{{ $t(tosString + '.akun.line7') }}</li>
          <li>{{ $t(tosString + '.akun.line8') }}</li>
          <li>{{ $t(tosString + '.akun.line9') }}</li>
          <li>{{ $t(tosString + '.akun.line10') }}</li>
          <li>{{ $t(tosString + '.akun.line11') }}</li>
          <li>{{ $t(tosString + '.akun.line12') }}</li>
          <li>{{ $t(tosString + '.akun.line13') }}</li>
          <li>{{ $t(tosString + '.akun.line14') }}</li>
          <li>{{ $t(tosString + '.akun.line15') }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'akun',
  props: ['tosString'],
};
</script>
