<template>
  <div class="container bg-white" id="corenting">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t(tosString + '.corenting.title') }}</h3>
        <div class="divider"></div>
        <ol>
          <li>
            {{ $t(tosString + '.corenting.line1') }}
          </li>
          <li>
            {{ $t(tosString + '.corenting.line2') }}
          </li>
          <li>
            {{ $t(tosString + '.corenting.line3') }}
            <ul>
              <li>{{ $t(tosString + '.corenting.line4') }}</li>
              <li>{{ $t(tosString + '.corenting.line5') }}</li>
              <li>{{ $t(tosString + '.corenting.line6') }}</li>
              <li>{{ $t(tosString + '.corenting.line7') }}</li>
              <li>{{ $t(tosString + '.corenting.line8') }}</li>
              <li>{{ $t(tosString + '.corenting.line9') }}</li>
            </ul>
          </li>
          <li>
            {{ $t(tosString + '.corenting.line10') }}
          </li>
          <li>
            {{ $t(tosString + '.corenting.line11') }}
          </li>
          <li>
            {{ $t(tosString + '.corenting.line12') }}
          </li>
          <li>
            {{ $t(tosString + '.corenting.line13') }}
          </li>
          <li>
            {{ $t(tosString + '.corenting.line14') }}
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'corenting',
  props: ['tosString'],
};
</script>
