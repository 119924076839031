<template>
  <div class="container bg-white" id="jualBeli">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t(tosString + '.jualBeli.title') }}</h3>
        <div class="divider"></div>
        <ol>
          <li>{{ $t(tosString + '.jualBeli.line1') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line2') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line3') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line4') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line5') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line6') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line7') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line8') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line9') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line10') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line11') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line12') }}</li>
          <li>{{ $t(tosString + '.jualBeli.line13') }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'jualBeli',
  props: ['tosString'],
};
</script>
