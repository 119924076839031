<template>
  <div class="container bg-white">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t(tosString + '.bookmark.title') }}</h3>
        <div class="divider"></div>
        <ol type="1">
          <li>
            <a href="#definisi">{{ $t(tosString + '.definisi.title') }}</a>
          </li>
          <li>
            <a href="#akun">{{ $t(tosString + '.akun.title') }}</a>
          </li>
          <li>
            <a href="#kategori">{{ $t(tosString + '.kategori.title') }}</a>
          </li>
          <li>
            <a href="#listing">{{ $t(tosString + '.listing.title') }}</a>
          </li>
          <li v-if="tosString === 'tos_jual'">
            <a href="#jualBeli">{{ $t(tosString + '.jualBeli.title') }}</a>
          </li>
          <!--          <li v-if="tosString === 'tos'">-->
          <!--            <a href="#penjadwalanTur">{{ $t(tosString + '.penjadwalanTur.title') }}</a>-->
          <!--          </li>-->
          <li v-if="tosString === 'tos'">
            <a href="#bookingProperti">{{ $t(tosString + '.bookingProperti.title') }}</a>
          </li>
          <li>
            <a href="#harga">{{ $t(tosString + '.harga.title') }}</a>
          </li>
          <li v-if="tosString === 'tos'">
            <a href="#penarikanDana">{{ $t(tosString + '.penarikanDana.title') }}</a>
          </li>
          <li v-if="tosString === 'tos'">
            <a href="#pembatalanSewa">{{ $t(tosString + '.pembatalanSewa.title') }}</a>
          </li>
          <li>
            <a href="#penolakanJaminan">{{ $t(tosString + '.penolakanJaminan.title') }}</a>
          </li>
          <li v-if="tosString === 'tos'">
            <a href="#spaceManager">{{ $t(tosString + '.spaceManager.title') }}</a>
          </li>
          <li v-if="tosString === 'tos'">
            <a href="#corenting">{{ $t(tosString + '.corenting.title') }}</a>
          </li>
          <li>
            <a href="#pelepasan">{{ $t(tosString + '.pelepasan.title') }}</a>
          </li>
          <li>
            <a href="#gantiRugi">{{ $t(tosString + '.gantiRugi.title') }}</a>
          </li>
          <li>
            <a href="#pilihanHukum">{{ $t(tosString + '.pilihanHukum.title') }}</a>
          </li>
          <li>
            <a href="#pembaruan">{{ $t(tosString + '.pembaruan.title') }}</a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bookmark',
  props: ['tosString'],
};
</script>
