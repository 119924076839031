<template>
  <div class="container bg-white" id="listing">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t(tosString + '.listing.title') }}</h3>
        <div class="divider"></div>
        <ol>
          <li>{{ $t(tosString + '.listing.line1') }}</li>
          <li>{{ $t(tosString + '.listing.line2') }}</li>
          <li v-html="$t(tosString + '.listing.line3')"></li>
          <li>{{ $t(tosString + '.listing.line4') }}</li>
          <li>{{ $t(tosString + '.listing.line5') }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'listing',
  props: ['tosString'],
};
</script>
