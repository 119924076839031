<template>
  <div class="term--service-wrapper">
    <banner :banner-text="$t(tosString + '.title')" />
    <div class="row">
      <div class="col-12 text-center d-flex justify-center main--image-wrapper">
        <img
          class="main--image"
          src="https://rentfix.s3-ap-southeast-1.amazonaws.com/static/img/static/photo-home-owner-auckland.jpg"
        />
      </div>
      <div v-html="$t(tosString + '.opening')" class="col-12 opening"></div>
      <div class="col-12 intro--wrapper">
        <div class="introduction" v-html="$t('privacy.content_intro')"></div>
      </div>
      <!--      <div class="switch mb-4">-->
      <!--        <div-->
      <!--          class="btn mr-2"-->
      <!--          :class="listingType === 'R' ? 'btn-primary' : 'btn-primary&#45;&#45;outline'"-->
      <!--          @click="changeListingType('R')"-->
      <!--        >-->
      <!--          {{ $t('general.rent') }}-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="btn"-->
      <!--          :class="listingType === 'S' ? 'btn-primary' : 'btn-primary&#45;&#45;outline'"-->
      <!--          @click="changeListingType('S')"-->
      <!--        >-->
      <!--          {{ $t('general.sell') }}-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="col-12 content--wrapper">
        <bookmark :tosString="tosString" class="mb-4"></bookmark>
        <definisi :tosString="tosString" class="mb-4"></definisi>
        <akun :tosString="tosString" class="mb-4"></akun>
        <kategori :tosString="tosString" class="mb-4"></kategori>
        <listing-term :tosString="tosString" class="mb-4"></listing-term>
        <!--        <penjadwalan-tur-->
        <!--          :tosString="tosString"-->
        <!--          class="mb-4"-->
        <!--          v-if="listingType === 'R'"-->
        <!--        ></penjadwalan-tur>-->
        <booking-properti
          :tosString="tosString"
          class="mb-4"
          v-if="listingType === 'R'"
        ></booking-properti>
        <jual-beli :tosString="tosString" class="mb-4" v-if="listingType === 'S'"></jual-beli>
        <harga :tosString="tosString" class="mb-4"></harga>
        <penarikan-dana
          :tosString="tosString"
          class="mb-4"
          v-if="listingType === 'R'"
        ></penarikan-dana>
        <pembatalan-sewa-tempat
          :tosString="tosString"
          class="mb-4"
          v-if="listingType === 'R'"
        ></pembatalan-sewa-tempat>
        <penolakan-jaminan :tosString="tosString" class="mb-4"></penolakan-jaminan>
        <space-manager
          :tosString="tosString"
          class="mb-4"
          v-if="listingType === 'R'"
        ></space-manager>
        <corenting :tosString="tosString" class="mb-4" v-if="listingType === 'R'"></corenting>
        <pelepasan :tosString="tosString" class="mb-4"></pelepasan>
        <ganti-rugi :tosString="tosString" class="mb-4"></ganti-rugi>
        <pilihan-hukum :tosString="tosString" class="mb-4"></pilihan-hukum>
        <pembaruan :tosString="tosString" class="mb-4"></pembaruan>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/utils/page-banner';
import Bookmark from '@/components/static-page/term-of-service/bookmark';
import Definisi from '@/components/static-page/term-of-service/definisi';
import Akun from '@/components/static-page/term-of-service/akun';
import Kategori from '@/components/static-page/term-of-service/kategori';
import ListingTerm from '@/components/static-page/term-of-service/listing';
// import PenjadwalanTur from '@/components/static-page/term-of-service/penjadwalan-tur';
import BookingProperti from '@/components/static-page/term-of-service/booking-properti';
import JualBeli from '@/components/static-page/term-of-service/jual-beli';
import Harga from '@/components/static-page/term-of-service/harga';
import PenarikanDana from '@/components/static-page/term-of-service/penarikan-dana';
import PembatalanSewaTempat from '@/components/static-page/term-of-service/pembatalan-sewa-tempat';
import PenolakanJaminan from '@/components/static-page/term-of-service/penolakan-jaminan';
import SpaceManager from '@/components/static-page/term-of-service/space-manager';
import Corenting from '@/components/static-page/term-of-service/corenting';
import Pelepasan from '@/components/static-page/term-of-service/pelepasan';
import GantiRugi from '@/components/static-page/term-of-service/ganti-rugi';
import PilihanHukum from '@/components/static-page/term-of-service/pilihan-hukum';
import Pembaruan from '@/components/static-page/term-of-service/pembaruan';
import { mapState } from 'vuex';

export default {
  name: 'term-of-service',
  components: {
    Banner,
    Bookmark,
    Definisi,
    Akun,
    Kategori,
    ListingTerm,
    // PenjadwalanTur,
    BookingProperti,
    JualBeli,
    Harga,
    PenarikanDana,
    PembatalanSewaTempat,
    PenolakanJaminan,
    SpaceManager,
    Corenting,
    Pelepasan,
    GantiRugi,
    PilihanHukum,
    Pembaruan,
  },
  computed: {
    ...mapState({
      listingType: (state) => state.v2.search.listingType,
    }),
    tosString() {
      if (this.listingType === 'R') {
        return 'tos';
      } else if (this.listingType === 'S') {
        return 'tos_jual';
      }
      return 'tos';
    },
  },
  methods: {
    changeListingType(listingType) {
      this.$store.commit('v2/search/SET_LISTING_TYPE', listingType);
    },
  },
};
</script>
