<template>
  <div class="container bg-white" id="spaceManager">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t(tosString + '.spaceManager.title') }}</h3>
        <div class="divider"></div>
        <ol>
          <li>{{ $t(tosString + '.spaceManager.line1') }}</li>
          <li>
            {{ $t(tosString + '.spaceManager.line2') }}
            <ul>
              <li>{{ $t(tosString + '.spaceManager.line3') }}</li>
              <li>{{ $t(tosString + '.spaceManager.line4') }}</li>
              <li>{{ $t(tosString + '.spaceManager.line5') }}</li>
              <li>{{ $t(tosString + '.spaceManager.line6') }}</li>
            </ul>
          </li>
          <li>{{ $t(tosString + '.spaceManager.line7') }}</li>
          <li>{{ $t(tosString + '.spaceManager.line8') }}</li>
          <li>{{ $t(tosString + '.spaceManager.line9') }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'spaceManager',
  props: ['tosString'],
};
</script>
