<template>
  <div class="container bg-white" id="penolakanJaminan">
    <div class="row">
      <div class="col-xs-18">
        <h3>{{ $t(tosString + '.penolakanJaminan.title') }}</h3>
        <div class="divider"></div>
        <p>{{ $t(tosString + '.penolakanJaminan.line1') }}</p>
        <p>{{ $t(tosString + '.penolakanJaminan.line2') }}</p>
        <p>{{ $t(tosString + '.penolakanJaminan.line3') }}</p>
        <p>{{ $t(tosString + '.penolakanJaminan.line4') }}</p>
        <ul>
          <li>{{ $t(tosString + '.penolakanJaminan.line5') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line6') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line7') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line8') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line9') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line10') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line11') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line12') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line13') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line14') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line15') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line16') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line17') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line18') }}</li>
          <li>{{ $t(tosString + '.penolakanJaminan.line19') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'penolakanJaminan',
  props: ['tosString'],
};
</script>
